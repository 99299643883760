<template>
  <div>
    <search-field title="条件筛选">
      <el-form :inline="true" size="small" label-width="100px">
        <el-form-item label="预算年度">
          <el-input clearable v-model="search.searchFields.year" placeholder="请输入年度"></el-input>
        </el-form-item>
        <el-form-item label="SKU">
          <el-input clearable v-model="search.searchFields.sku" placeholder="请输入SKU名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" size="small" @click="getBudgetList(1)">查询</el-button>
        <el-button type="primary" size="small" @click="clearSearch()">重置</el-button>
      </div>
    </search-field>
    <div class="page-content">
      <div class="button-field">
        <el-button type="primary" size="small" @click="handleAdd()">添加</el-button>
        <el-button type="primary" size="small" @click="handleExportTemp()">下载模板</el-button>
      </div>
      <v-table
          :data="options.data"
          :columns="options.columns"
          :isBackPage="true"
          :totalCount="options.totalCount"
          :defaultcurrentPage="search.searchFields.page"
          @handleCurrentChange="handlePageChange"
          @handleSizeChange="handleSizeChange"
          @buttonClick="handleButtonClick"
      >
      </v-table>
    </div>
    <el-dialog
        title="下载"
        width="25%"
        :visible.sync="exportBMDialog"
    >
      <el-form size="small" label-width="110px">
        <el-form-item
            label="预算年份"
            required
            :error="errorBags.collect('预算年份')[0]"
        >
          <el-input
              v-model="budgetMonthForm.year"
              placeholder="请输入年份"
              v-validate="'required'"
              data-vv-name="年份"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn-container">
        <el-button @click="closeSMModal">取消</el-button>
        <el-button type="primary" @click="handleSMSubmit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="新增"
        width="25%"
        :visible.sync="shopAddDialog"
    >
      <el-form size="small" label-width="110px">
        <el-form-item
            label="SKU编码"
            required
            :error="errorBags.collect('SKU编码')[0]"
        >
          <el-input

              v-model="budgetForm.sku_code"
              placeholder="请输入SKU编码"
              v-validate="'required'"
              data-vv-name="SKU编码"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="SKU名称"
            required
            :error="errorBags.collect('SKU名称')[0]"
        >
          <el-input
              type="tel"
              v-model="budgetForm.sku"
              maxlength="12"
              placeholder="请输入SKU名称"
              v-validate="'required'"
              data-vv-name="SKU名称"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="品牌名称"
            required
            :error="errorBags.collect('品牌名称')[0]"
        >
          <el-input
              type="tel"
              v-model="budgetForm.brand_cn"
              maxlength="12"
              placeholder="请输入品牌名称"
              v-validate="'required'"
              data-vv-name="品牌名称"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="预算年份"
            required
            :error="errorBags.collect('预算年份')[0]"
        >
          <el-input
              type="tel"
              v-model="budgetForm.year"
              maxlength="12"
              placeholder="请输入预算年份"
              v-validate="'required'"
              data-vv-name="预算年份"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="POS单价"
            required
            :error="errorBags.collect('POS单价')[0]"
        >
          <el-input
              type="tel"
              v-model="budgetForm.sku_pos"
              maxlength="12"
              placeholder="请输入POS单价"
              v-validate="'required'"
              data-vv-name="POS单价"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="成本单价"
            required
            :error="errorBags.collect('成本单价')[0]"
        >
          <el-input
              type="tel"
              v-model="budgetForm.sku_cost"
              maxlength="12"
              placeholder="请输入成本单价"
              v-validate="'required'"
              data-vv-name="成本单价"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="预算数量"
            required
            :error="errorBags.collect('预算数量')[0]"
        >
          <el-input
              type="tel"
              v-model="budgetForm.num"
              maxlength="12"
              placeholder="请输入预算数量"
              v-validate="'required'"
              data-vv-name="预算数量"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="开票单价"
            required
            :error="errorBags.collect('开票单价')[0]"
        >
          <el-input
              type="tel"
              v-model="budgetForm.sku_kp_price"
              maxlength="12"
              placeholder="请输入开票单价"
              v-validate="'required'"
              data-vv-name="开票单价"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="开票扣率"
            required
            :error="errorBags.collect('开票扣率')[0]"
        >
          <el-input
              type="tel"
              v-model="budgetForm.sku_kp_ratio"
              maxlength="12"
              placeholder="请输入开票扣率"
              v-validate="'required'"
              data-vv-name="开票扣率"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" required :error="errorBags.collect('状态')[0]">
          <el-select v-model="budgetForm.status" v-validate="'required'" data-vv-name="状态">
            <el-option
                v-for="item in statusOptions"
                :key="item.code"
                :label="item.value"
                :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="btn-container">
          <el-button @click="closeModal">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

import vTable from '@/components/tools/vtable'
import searchField from '@/components/tools/searchField'
import {BAApi} from '@/api'
import {formatDate} from '@/libs/utils'

export default {
  data() {
    return {
      options: {
        columns: [
          {
            label: '品牌名称',
            key: 'brand_cn'
          },
          {
            label: 'SKU编码',
            key: 'sku_code'
          },
          {
            label: 'SKU名称',
            key: 'sku'
          },
          {
            label: '数量',
            key: 'num'
          },
          {
            label: '年度',
            key: 'year'
          },
          {
            label: 'POS单价',
            key: 'sku_pos'
          },
          {
            label: '成本单价',
            key: 'sku_cost'
          },
          {
            label: '开票单价',
            key: 'sku_kp_price'
          },
          {
            label: '开票扣率',
            key: 'sku_kp_ratio'
          },
          {
            label: '状态',
            key: 'status'
          },
          {
            label: '创建时间',
            type: 'render',
            render: (h, {row}) => {
              return (
                  <div>
                    <div class="status-td">
                      {
                        <div>{formatDate(Number(row.create_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                      }
                    </div>
                  </div>
              )
            }
          },
          {
            label: '更新时间',
            type: 'render',
            render: (h, {row}) => {
              return (
                  <div>
                    <div class="status-td">
                      {
                        <div>{formatDate(Number(row.update_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                      }
                    </div>
                  </div>
              )
            }
          },
          {
            label: '操作',
            type: 'action',
            selectButton: true,
            buttonInfos: [
              {
                name: 'edit',
                label: '编辑',
                color: 'primary'
              },
            ]
          }
        ],
        data: [],
        totalCount: 0,
        loading: false,
      },
      search: {
        searchFields: {
          page: 1,
          count: 20,
          year: '',
          brand_cn: '',
          sku: '',
        }
      },
      statusOptions: [
        {
          code: 1,
          value: '正常'
        }, {
          code: 0,
          value: '下架'
        },
      ],
      exportBMDialog: false,
      shopAddDialog: false,
      budgetMonthForm: {
        year: '',
        type: 1,
        status: 1
      },
      budgetForm: {
        brand_cn: '',
        sku: '',
        sku_code: '',
        num: '',
        sku_pos: '',
        sku_cost: '',
        sku_kp_price: '',
        sku_kp_ratio: '',
        year: '',
        status: 1
      },
      editBudgetId: '',
    }
  },

  methods: {
    // 后端分页
    handlePageChange(page) {
      this.search.searchFields.page = page
      this.getBudgetList()
    },
    handleSizeChange(count) {
      this.search.searchFields.count = count
      this.getBudgetList(1)
    },
    async getBudgetList(page) {
      let loading = this.$loading()
      this.search.searchFields.page = page || this.search.searchFields.page
      try {
        let data = (await BAApi.getBudgetList(this.search.searchFields)).data
        if (data.code === 0) {
          this.$validator.reset()
          this.options.data = data.budget.map(item => {
            item.btnList = ['edit']
            return item
          })
          this.options.totalCount = parseInt(data.total_count) || 0
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    clearSearch() {
      this.search = {
        searchFields: Object.assign({count: this.search.searchFields.count}, {
          page: 1,
          year: '',
          brand_cn: '',
          sku: ''
        })
      }
    },
    async handleButtonClick(value) {
      let type = value.button,
          data = value.data;
      this.editBudgetId = data.id;

      if (type === 'edit') {
        this.shopAddDialog = true;
        this.budgetForm.brand_cn = data.brand_cn;
        this.budgetForm.sku = data.sku;
        this.budgetForm.status = Number(data.status);
        this.budgetForm.sku_code = data.sku_code;
        this.budgetForm.sku_pos = data.sku_pos;
        this.budgetForm.sku_cost = data.sku_cost;
        this.budgetForm.sku_kp_price = data.sku_kp_price;
        this.budgetForm.sku_kp_ratio = data.sku_kp_ratio;
        this.budgetForm.num = data.num;
        this.budgetForm.year = data.year;

      }
    },
    handleExportTemp() {
      this.exportBMDialog = true
    },
    closeSMModal() {
      this.resetSMForm()
      this.exportBMDialog = false
    },
    handleAdd() {
      this.edit = false
      this.resetForm()
      this.$validator.reset()
      this.shopAddDialog = true
    },
    closeModal() {
      this.resetForm()
      this.shopAddDialog = false
    },
    handleSMSubmit() {
      this.$validator.validate().then(year => {
        if (year) {
          this.exportBudgetSM()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    async exportBudgetSM() {
      let loading = this.$loading()
      try {
        let params = {
          ...this.budgetMonthForm,
        }
        let data = (await BAApi.exportTemp(params)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.resetSMForm()
          this.exportBMDialog = false
          let time = formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss').dateString
          let downLink = document.createElement('a')
          downLink.href = data.data.file_path
          downLink.download = `${time}-预算年度列表`
          downLink.click()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },
    handleSubmit() {
      this.$validator.validate().then(status => {
        if (status) {
          this.addBudget()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    async addBudget() {
      let loading = this.$loading()
      try {
        let params = {
          ...this.budgetForm,
          id: this.editBudgetId,
        }
        let data = (await BAApi.addOrEditBudget(params)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.$message.success('添加预算成功')
          this.resetForm()
          this.shopAddDialog = false
          this.getBudgetList()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },

    resetForm() {
      let obj = {
        brand_cn: '',
        sku: '',
        sku_code: '',
        sku_pos: '',
        sku_cost: '',
        sku_kp_price: '',
        sku_kp_ratio: '',
        num: '',
        year: ''
      }
      this.budgetForm = obj
      this.editBudgetId = '';
    },
    resetSMForm() {
      let obj = {
        year: ''
      }
      this.budgetMonthForm = obj
    }
  },

  mounted() {
    this.getBudgetList()
  },
  components: {
    vTable, searchField
  }
}
</script>

<style lang="less">
.status-td {
  display: flex;
  align-items: center;
  justify-content: center;

  .tag {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }

  .tag2 {
    background-color: #67C23A;
  }

  .tag3 {
    background-color: #F56C6C;
  }
}
</style>

